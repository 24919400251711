import { ReactNode } from 'react';

interface CardProps {
  children: ReactNode;
  defaultPadding?: boolean;
  overflowHidden?: boolean;
  shadow?: boolean;
}

export const Card = ({
  children,
  defaultPadding = true,
  overflowHidden = true,
  shadow = true,
}: CardProps) => {
  const padding = defaultPadding ? 'py-4 px-6' : '';
  const overflow = overflowHidden ? 'overflow-hidden' : '';
  const shadowClass = shadow ? 'shadow-md' : '';

  return (
    <div
      className={`bg-gray-50/70 dark:bg-gray-800/75 border border-gray-200 dark:border-gray-700 rounded-lg ${padding} ${overflow} ${shadowClass}`}
    >
      {children}
    </div>
  );
};
