import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { routes } from '../routes';
import { useDonationsStore } from '../../app-state/donationsStore';
import { useImpactData } from '../../utils/use-impact-data';
import { formatDateString } from '../../utils/format-date-string';
import { formatGBP } from '../../utils/format-gbp';
import { Card } from '../../components/card/card';
import { ImpactSummaryTable } from '../../components/impact-summary-table/impact-summary-table';
import { ImpactDateRangePicker } from '../../components/impact-date-range-picker/impact-date-range-picker';
import { ImpactSummarySentence } from '../../components/impact-summary-sentence/impact-summary-sentence';
import { ImpactQuickCharityDataCard } from '../../components/impact-quick-charity-data-card/impact-quick-charity-data-card';
import { ImpactSankeyDiagram } from '../../components/impact-sankey-diagram/impact-sankey-diagram';
import { ImpactDonutDonationAmounts } from '../../components/impact-donut-donation-amounts/impact-donut-donation-amounts';
import { Button } from '../../components/button/button';

export const Impact = () => {
  const donationsList = useDonationsStore((state) => state.donationsList);
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [donutViewBoxSize, setDonutViewBoxSize] = useState<number>(600);

  const updateDonutViewBoxSize = () => {
    const newSize = Math.min(window.innerWidth - 100, 600);
    setDonutViewBoxSize(Math.max(newSize, 200));
  };

  useEffect(() => {
    updateDonutViewBoxSize();

    window.addEventListener('resize', updateDonutViewBoxSize);

    return () => {
      window.removeEventListener('resize', updateDonutViewBoxSize);
    };
  }, []);

  const { stats, charityDonationsData, categoryWordData } = useImpactData(
    donationsList,
    startDate,
    endDate,
  );

  // Destructure necessary stats
  const {
    totalDonated,
    numberOfDonations,
    uniqueCharities,
    charityQuickData,
    largestSingleDonation,
    mostRecentDonationDate,
    mostRecentDonationAmount,
    averageDonationAmount,
    topCharitiesByAmount,
    maxDonationAmount,
    topCharitiesByCount,
    maxDonationCount,
    topCharitiesByStreak,
    longestStreak,
  } = stats;

  const noDonations =
    startDate === null && endDate === null && numberOfDonations === 0;

  const noDonationsBetweenDates =
    startDate !== null && endDate !== null && numberOfDonations === 0;

  if (noDonations) {
    return (
      <>
        <h1>Your impact</h1>
        <div className="py-3">
          <p>
            Once you start making donations, you will be able to see your impact
            here.
          </p>

          <div className="mt-6">
            <Button onClick={() => navigate(routes.DISCOVER)} variant="CTA">
              Get started
            </Button>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <h1>Your impact</h1>
      <div className="text-sm font-semibold -mt-2 mb-6">
        (Donations can take up to an hour to appear here)
      </div>

      <ImpactDateRangePicker
        startDate={startDate}
        endDate={endDate}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
        onClearDates={() => {
          setStartDate(null);
          setEndDate(null);
        }}
      />

      {noDonationsBetweenDates ? (
        <Card>
          No donations between your selected dates. To see all donations clear
          your selection or visit your{' '}
          <Link to={routes.ACCOUNT} className="link">
            donation history
          </Link>
        </Card>
      ) : (
        <>
          <div className="mt-6 mb-12">
            <Card overflowHidden={false}>
              <ImpactSummarySentence
                startDate={startDate}
                endDate={endDate}
                numberOfUniqueCharities={uniqueCharities.size}
                numberOfDonations={numberOfDonations}
                totalDonated={formatGBP(totalDonated.toString())}
              />

              <div className="grid lg:grid-cols-[1.5fr_1fr] items-center gap-6 mt-6">
                <div className="py-6 flex flex-wrap gap-6">
                  {charityQuickData.map((charityData) => (
                    <ImpactQuickCharityDataCard
                      key={charityData.link}
                      name={charityData.name}
                      url={charityData.url}
                      link={`${routes.DISCOVER}/${charityData.link}`}
                      amountDonated={formatGBP(
                        charityData.amountDonated.toString(),
                      )}
                      donationCount={charityData.donationCount}
                    />
                  ))}
                </div>

                <div className="max-w-[600px]">
                  <ImpactDonutDonationAmounts
                    data={charityDonationsData}
                    viewBoxSize={donutViewBoxSize}
                  />
                </div>
              </div>
            </Card>
          </div>

          <div className="grid max-w-4xl xl:grid-cols-2 xl:max-w-none items-start gap-16">
            <ImpactSummaryTable
              startDate={startDate}
              endDate={endDate}
              numberOfDonations={numberOfDonations}
              totalDonated={formatGBP(totalDonated.toString())}
              averageDonationAmount={formatGBP(
                averageDonationAmount.toString(),
              )}
              largestSingleDonation={formatGBP(
                largestSingleDonation.toString(),
              )}
              mostRecentDonationDate={
                mostRecentDonationDate
                  ? formatDateString(mostRecentDonationDate)
                  : null
              }
              mostRecentDonationAmount={mostRecentDonationAmount}
              topCharitiesByAmount={topCharitiesByAmount}
              maxDonationAmount={formatGBP(maxDonationAmount.toString())}
              topCharitiesByCount={topCharitiesByCount}
              maxDonationCount={maxDonationCount}
              topCharitiesByStreak={topCharitiesByStreak}
              longestStreak={longestStreak}
            />

            <ImpactSankeyDiagram categoryWordData={categoryWordData} />
          </div>
        </>
      )}
    </>
  );
};
