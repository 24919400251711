import { useEffect } from 'react';
import { useUserStore } from '../app-state/userStore';

export const useCsrfToken = () => {
  const { fetchAndSetCsrfToken } = useUserStore.getState();
  const csrfToken = useUserStore((state) => state.csrfToken);
  const csrfTokenLoading = useUserStore((state) => state.csrfTokenLoading);

  useEffect(() => {
    if (!csrfToken && !csrfTokenLoading) {
      fetchAndSetCsrfToken();
    }
  }, [csrfToken, csrfTokenLoading, fetchAndSetCsrfToken]);

  return { csrfToken, csrfTokenLoading };
};
