import flower from '../header-logo/logo192.png';

export const LoadingSpinner = () => (
  <img
    src={flower}
    alt="loading..."
    className="h-8 w-8 animate-spin"
    data-testid="loader"
  />
);
