import { useState, useCallback } from 'react';
import { useDonationsStore } from '../app-state/donationsStore';
import { ApiRoutes, getApiUrl } from './get-api-url';
import { usePostRequest } from './use-post-request';
import { Charity } from '../app-state/charityStore';

interface CharityAddState {
  charityAddInProgress: boolean;
  charityAddError: Error | null;
}

export const useCharityAdd = () => {
  const currentDonationConfig = useDonationsStore(
    (state) => state.currentDonationConfig,
  );
  const { adjustDonationConfig } = useDonationsStore.getState();

  const { post, csrfTokenLoading } = usePostRequest();
  const [charitiesUpdateState, setCharitiesUpdateState] =
    useState<CharityAddState>({
      charityAddInProgress: false,
      charityAddError: null,
    });

  const handleCharityAdd = useCallback(
    async (charityId: string, percentage: number) => {
      if (charitiesUpdateState.charityAddInProgress) return;

      setCharitiesUpdateState((prevState) => ({
        ...prevState,
        charityAddInProgress: true,
        charityAddError: null,
      }));

      const donationsToPost = [
        ...currentDonationConfig,
        {
          charity: {
            id: charityId,
          },
          percentage,
        },
      ];

      if (!csrfTokenLoading) {
        const url = `${getApiUrl()}${ApiRoutes.USER_DONATIONS}`;
        const body = { charities: donationsToPost };
        const handleSuccess = (data: any) => {
          if (
            !data.charities ||
            (data.charities && data.charities.length === 0) ||
            !data.charities.some((charity: Charity) => charity.id === charityId)
          ) {
            setCharitiesUpdateState((prevState) => ({
              ...prevState,
              charityAddInProgress: false,
              charityAddError: new Error(
                data.message || 'Could not add charity, please try again',
              ),
            }));
          }

          adjustDonationConfig(data.charities, true);

          setCharitiesUpdateState((prevState) => ({
            ...prevState,
            charityAddInProgress: false,
          }));
        };

        const handleError = (error: Error) => {
          setCharitiesUpdateState((prevState) => ({
            ...prevState,
            charityAddInProgress: false,
            charityAddError: error,
          }));
        };

        await post(url, body, handleSuccess, handleError);
      } else {
        setCharitiesUpdateState((prevState) => ({
          ...prevState,
          charityAddInProgress: true,
        }));
      }
    },
    [
      adjustDonationConfig,
      currentDonationConfig,
      charitiesUpdateState.charityAddInProgress,
      post,
      csrfTokenLoading,
    ],
  );

  return { handleCharityAdd, charitiesUpdateState };
};
