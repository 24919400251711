import { ReactNode } from 'react';
import classNames from 'classnames';

export type ButtonVariant = 'default' | 'CTA' | 'clean';

interface ButtonProps {
  children: ReactNode;
  variant?: ButtonVariant;
  disabled?: boolean;
  onClick?: () => void;
  dataTestId?: string;
  padding?: 'small' | 'default';
}

export const Button = ({
  children,
  variant = 'default',
  disabled,
  onClick,
  dataTestId,
  padding = 'default',
  ...props
}: ButtonProps) => {
  const buttonClasses = classNames(
    'uppercase disabled:opacity-50 disabled:pointer-events-none inline-flex justify-center items-center gap-2 border border-transparent font-medium transition-all text-sm',
    {
      'py-2 px-3': padding === 'default',
      'py-1 px-2': padding === 'small',
      'rounded-md': variant !== 'clean',
      'shadow-sm bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600 border border-gray-300 dark:border-gray-600 text-gray-700 hover:text-gray-900 dark:text-white':
        variant === 'default',
      'bg-green-300 hover:bg-green-400 dark:text-gray-800': variant === 'CTA',
    },
  );

  return (
    <button
      className={buttonClasses}
      disabled={disabled}
      onClick={onClick}
      data-testid={dataTestId}
      {...props}
    >
      {children}
    </button>
  );
};
