export const constructPostRequestOptions = <T>(
  csrfToken?: string,
  body?: T
) => {
  if (!csrfToken) {
    throw new Error('CSRF token not set');
  }

  return {
    method: 'POST',
    credentials: 'include' as RequestCredentials,
    headers: {
      'Content-Type': 'application/json',
      'X-CsrfToken': csrfToken,
    },
    body: body ? JSON.stringify(body) : null,
  };
};
