import { useSearchParams, Link } from 'react-router-dom';
import { useUserStore } from '../../app-state/userStore';
import { routes } from '../routes';

export const FailedLoginLink = () => {
  const [searchParams] = useSearchParams();
  const reason = searchParams.get('reason');
  const email = useUserStore((state) => state.email);

  if (email) {
    return (
      <div>
        <h1>Login attempt failed</h1>
        <div>
          You are already logged in as {email}. Log out to try again or head{' '}
          <Link className="link" to={routes.HOME}>
            home
          </Link>
        </div>
      </div>
    );
  }

  let reasonContent;
  switch (reason) {
    case 'superseded':
      reasonContent = (
        <div>Your login link has been superseded by a more recent one.</div>
      );
      break;
    case 'expired':
      reasonContent = (
        <div>
          Your login link has expired. Try logging in again to get a new link.
        </div>
      );
      break;
    case 'invalid':
      reasonContent = (
        <div>
          Your login link is invalid. Please check the link or try logging in
          again to get a new link.
        </div>
      );
      break;
    default:
      reasonContent = <div>Unknown reason. Please contact support.</div>;
      break;
  }

  return (
    <div>
      <h1>Failed Login</h1>
      {reasonContent}
    </div>
  );
};
