import BigNumber from 'bignumber.js';
import { Link } from 'react-router-dom';
import { routes } from '../../routes/routes';
import { Card } from '../card/card';
import { formatGBP } from '../../utils/format-gbp';
import { formatDateString } from '../../utils/format-date-string';

interface ImpactSummaryTableProps {
  startDate: Date | null;
  endDate: Date | null;
  numberOfDonations: number;
  totalDonated: string;
  averageDonationAmount: string;
  largestSingleDonation: string;
  mostRecentDonationDate: string | null;
  mostRecentDonationAmount: BigNumber | null;
  topCharitiesByAmount: string[];
  maxDonationAmount: string;
  topCharitiesByCount: string[];
  maxDonationCount: number;
  topCharitiesByStreak: string[];
  longestStreak: number;
}

export const ImpactSummaryTable = ({
  startDate,
  endDate,
  numberOfDonations,
  totalDonated,
  averageDonationAmount,
  largestSingleDonation,
  mostRecentDonationDate,
  mostRecentDonationAmount,
  topCharitiesByAmount,
  maxDonationAmount,
  topCharitiesByCount,
  maxDonationCount,
  topCharitiesByStreak,
  longestStreak,
}: ImpactSummaryTableProps) => {
  const hasStartDate = startDate !== null;
  const hasEndDate = endDate !== null;
  const baseTextStyle =
    'px-6 py-3 text-sm font-semibold text-gray-600 dark:text-gray-300';
  const baseValueStyle = 'px-4 py-3 text-lg font-bold';

  const borderTopStyles = {
    numberOfDonations: 'border-t border-transparent',
    totalDonated: 'border-t border-fruitcakeBlue',
    averageDonationAmount: 'border-t border-fruitcakeGreen',
    largestSingleDonation: 'border-t border-fruitcakeGold',
    mostRecentDonationDate: 'border-t border-fruitcakePurple',
    topCharitiesByAmount: 'border-t border-fruitcakeOrange',
    topCharitiesByCount: 'border-t border-fruitcakeRed',
    longestStreak: 'border-t border-fruitcakeBlue',
  };

  return (
    <div className="w-full">
      <h2>
        Your donation stats{' '}
        {hasStartDate || hasEndDate
          ? `between ${
              hasStartDate
                ? formatDateString(startDate!.toString(), false)
                : 'Starting with Fruitcake'
            } and ${
              hasEndDate
                ? formatDateString(endDate!.toString(), false)
                : 'today'
            }`
          : 'since starting with Fruitcake'}
      </h2>
      <Card defaultPadding={false}>
        <table className="w-full table-auto">
          <tbody>
            <tr className={borderTopStyles.numberOfDonations}>
              <td className={baseTextStyle}>Number of Donations</td>
              <td className={baseValueStyle}>
                {numberOfDonations}{' '}
                <Link
                  to={`${routes.ACCOUNT}#donation-history`}
                  className="link text-base pl-4"
                >
                  View your donation history
                </Link>
              </td>
            </tr>
            <tr className={borderTopStyles.totalDonated}>
              <td className={baseTextStyle}>Total Amount Donated</td>
              <td className={baseValueStyle}>{totalDonated}</td>
            </tr>
            <tr className={borderTopStyles.averageDonationAmount}>
              <td className={baseTextStyle}>Average Donation Amount</td>
              <td className={baseValueStyle}>{averageDonationAmount}</td>
            </tr>
            <tr className={borderTopStyles.largestSingleDonation}>
              <td className={baseTextStyle}>Largest Single Donation</td>
              <td className={baseValueStyle}>{largestSingleDonation}</td>
            </tr>
            {mostRecentDonationDate && (
              <tr className={borderTopStyles.mostRecentDonationDate}>
                <td className={baseTextStyle}>Most Recent Donation</td>
                <td className={baseValueStyle}>
                  {mostRecentDonationDate}
                  {mostRecentDonationAmount && (
                    <>: {formatGBP(mostRecentDonationAmount?.toString())}</>
                  )}
                </td>
              </tr>
            )}
            {topCharitiesByAmount.length > 0 && (
              <tr className={borderTopStyles.topCharitiesByAmount}>
                <td className={baseTextStyle}>Top Charities by Amount</td>
                <td className={baseValueStyle}>
                  {topCharitiesByAmount.join(', ')} ({maxDonationAmount})
                </td>
              </tr>
            )}
            {topCharitiesByCount.length > 0 && (
              <tr className={borderTopStyles.topCharitiesByCount}>
                <td className={baseTextStyle}>
                  Top Charities by Number of Donations
                </td>
                <td className={baseValueStyle}>
                  {topCharitiesByCount.join(', ')} ({maxDonationCount}{' '}
                  donations)
                </td>
              </tr>
            )}
            {topCharitiesByStreak.length > 0 && longestStreak > 1 && (
              <tr className={borderTopStyles.longestStreak}>
                <td className={baseTextStyle}>Longest Charity Giving Streak</td>
                <td className={baseValueStyle}>
                  {topCharitiesByStreak
                    .map(
                      (charityName) =>
                        `${charityName} (${longestStreak} in a row)`,
                    )
                    .join(', ')}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Card>
    </div>
  );
};
