import { create } from 'zustand';
import { useNetworkStore } from './networkStore';
import { useUIStore } from './uiStore';
import { ApiRoutes, getApiUrl } from '../utils/get-api-url';
import { constructGetRequestOptions } from '../utils/construct-get-request-options';

interface UserState {
  email?: string;
  emailLoading: boolean;
  paymentReference?: string;
  csrfToken?: string;
  csrfTokenLoading: boolean;
  emailLinkSent: boolean;
}

interface UserActions {
  getUserEmail: () => void;
  setUserData: (email: string, paymentReference: string) => void;
  clearUserData: () => void;
  fetchAndSetCsrfToken: () => Promise<void>;
  setCsrfTokenLoading: (isLoading: boolean) => void;
  setEmailLinkSent: (emailLinkSent: boolean) => void;
}

const initialUserState: UserState = {
  email: undefined,
  emailLoading: true,
  paymentReference: undefined,
  csrfToken: undefined,
  csrfTokenLoading: true,
  emailLinkSent: false,
};

export const useUserStore = create<UserState & UserActions>((set, get) => ({
  ...initialUserState,

  getUserEmail: () => {
    const { addToFetchQueue } = useNetworkStore.getState();
    const { setUserData } = get();

    set((state) => ({
      ...state,
      emailLoading: true,
    }));

    addToFetchQueue(
      `${getApiUrl()}${ApiRoutes.USER_PROFILE}`,
      constructGetRequestOptions(),
      (data) => {
        if (data.email) {
          setUserData(data.email, data.payment_reference);
        }

        set((state) => ({ ...state, emailLoading: false }));
      },
      (error) => {
        console.error('Error fetching user email:', error);

        set((state) => ({ ...state, emailLoading: false }));
      },
    );
  },
  setUserData: (email, paymentReference) => {
    const { email: currentEmail, fetchAndSetCsrfToken } = get();
    if (currentEmail !== email) {
      // Login state has changed, fetch new CSRF token
      fetchAndSetCsrfToken();
    }
    set((state) => ({
      ...state,
      email,
      paymentReference,
    }));
  },
  clearUserData: () => {
    set(() => initialUserState);
  },
  fetchAndSetCsrfToken: async () => {
    const { clearError, displayError, errorToDisplay } = useUIStore.getState();

    const fetchCsrfToken = async (): Promise<string> => {
      const response = await fetch(
        `${getApiUrl()}${ApiRoutes.CSRF_TOKEN}`,
        constructGetRequestOptions(),
      );
      if (!response.ok) {
        throw new Error('Failed to fetch CSRF token');
      }
      const data = await response.json();
      return data.token as string;
    };

    const errorMessage =
      "We're missing some data from the server. Please reload the page to try again.";

    try {
      get().setCsrfTokenLoading(true);

      const csrfToken = await fetchCsrfToken();
      const csrfTokenInState = get().csrfToken;

      if (csrfToken && errorToDisplay === errorMessage) {
        clearError();
      }

      if (csrfToken === csrfTokenInState) return;
      set((state) => ({
        ...state,
        csrfToken,
      }));
    } catch (error) {
      console.error('Error fetching CSRF token:', error);
      displayError(errorMessage);
    } finally {
      get().setCsrfTokenLoading(false);
    }
  },
  setCsrfTokenLoading: (isLoading) => {
    set((state) => ({
      ...state,
      csrfTokenLoading: isLoading,
    }));
  },
  setEmailLinkSent: (emailLinkSent) => {
    set((state) => ({
      ...state,
      emailLinkSent,
    }));
  },
}));
