export const formatDateString = (
  dateString: string,
  hoursAndMinutes: boolean = true,
) => {
  const hoursAndMinuteOptions: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
  };

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  const date = new Date(dateString);
  return new Intl.DateTimeFormat(
    'en-GB',
    hoursAndMinutes
      ? {
          ...options,
          ...hoursAndMinuteOptions,
        }
      : options,
  ).format(date);
};
