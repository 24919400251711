import { useNavigate } from 'react-router-dom';
import {
  QuestionMarkCircleIcon,
  ChatBubbleLeftRightIcon,
} from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import { routes } from '../routes';
import { Button } from '../../components/button/button';

export const Home = () => {
  const navigate = useNavigate();

  return (
    <div className="pt-6">
      <div className="text-base leading-7">
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Welcome to Fruitcake!
        </h1>
        <p className="mt-6 text-xl leading-8">
          Thank you for being part of our private alpha phase. We're excited to
          have you here testing Fruitcake!
        </p>
        <h2 className="mt-12 text-xl font-bold">Alpha Version Disclaimer</h2>
        <p className="mt-6">
          We're early and progressing quickly. If the app is slow or features
          are failing please let us know.
        </p>
        <p className="mt-6">
          If for any reason there's an issue getting a donation to a charity, we
          will do everything we can to resolve it, and issue a refund if we are
          unable to do so.
        </p>
        <p className="mt-12 text-lg font-bold">
          Your feedback is incredibly important to us and will shape the future
          of the project. Thank you!
        </p>

        <div className="mt-10">
          <ul className="max-w-5xl flex flex-col sm:flex-row items-center mt-12 gap-6">
            <li className="flex gap-x-3">
              <QuestionMarkCircleIcon
                className="mt-1 h-5 w-5 flex-none text-indigo-600"
                aria-hidden="true"
              />
              <span>
                <strong className="font-semibold">Questions?</strong> If you
                have any questions, firstly check out our{' '}
                <Link className="link" to={routes.FAQS}>
                  FAQs
                </Link>{' '}
                to see if we've already answered it. If not, please get in touch
                at{' '}
                <a
                  className="link"
                  href="mailto:fruitcakeapp@gmail.com?subject=Fruitcake%20App%20Question"
                >
                  fruitcakeapp@gmail.com
                </a>
                . We'd love to hear from you and will get back to you as quickly
                as we can.
              </span>
            </li>
            <li className="flex gap-x-3">
              <ChatBubbleLeftRightIcon
                className="mt-1 h-5 w-5 flex-none text-indigo-600"
                aria-hidden="true"
              />
              <span>
                <strong className="font-semibold">Feedback:</strong> If you have
                any suggestions or encounter any issues, we want to hear it!
                Whether it's a minor niggle or a huge feature suggestion, it's
                all really important to us:{' '}
                <a
                  className="link"
                  href="mailto:fruitcakeapp@gmail.com?subject=Fruitcake%20App%20Feedback"
                >
                  fruitcakeapp@gmail.com
                </a>
                .
              </span>
            </li>
          </ul>
        </div>

        <div className="mt-12">
          <Button onClick={() => navigate(routes.DISCOVER)} variant="CTA">
            Get started
          </Button>
        </div>
      </div>
    </div>
  );
};
