import { create } from 'zustand';
import { useNetworkStore } from './networkStore';
import { useUIStore } from './uiStore';
import { ApiRoutes, getApiUrl } from '../utils/get-api-url';
import { constructGetRequestOptions } from '../utils/construct-get-request-options';

export interface Charity {
  id: string;
  name: string;
  description: string;
  logo_url: string;
  banner_url: string;
  strapline: string;
  categories: string[];
}

interface CharityState {
  charities: Charity[];
  charitiesLoading: boolean;
  recommendedCharitiesLoading: boolean;
  recommendedCharities: Charity[];
}

interface CharityActions {
  fetchCharities: () => void;
  fetchRecommendedCharities: (limit?: number, randomness?: number) => void;
}

export const useCharityStore = create<CharityState & CharityActions>(
  (set, get) => ({
    charities: [],
    charitiesLoading: false,
    recommendedCharitiesLoading: false,
    recommendedCharities: [],

    fetchCharities: async () => {
      const { addToFetchQueue } = useNetworkStore.getState();
      const { displayError } = useUIStore.getState();
      const { charities } = get();

      set((state) => ({
        ...state,
        charitiesLoading: true,
      }));

      if (charities.length > 0) return;

      const url = `${getApiUrl()}${ApiRoutes.CHARITY_LIST}`;

      const requestOptions = constructGetRequestOptions();

      const handleError = () => {
        displayError(
          'There was an issue fetching charities from the server. Please refresh the page to try again.',
        );
        set((state) => ({
          ...state,
          charitiesLoading: false,
        }));
      };

      const handleSuccess = (data: { results: Charity[] }) => {
        const { charities } = get();
        const newCharities = data?.results || [];

        if (JSON.stringify(charities) !== JSON.stringify(newCharities)) {
          set((state) => ({
            ...state,
            charities: newCharities,
            charitiesLoading: false,
          }));
        } else {
          set((state) => ({
            ...state,
            charitiesLoading: false,
          }));
        }
      };

      addToFetchQueue(url, requestOptions, handleSuccess, handleError);
    },

    fetchRecommendedCharities: (limit = 4, randomness = 1) => {
      const { addToFetchQueue } = useNetworkStore.getState();
      const { displayError } = useUIStore.getState();

      set((state) => ({ ...state, recommendedCharitiesLoading: true }));

      const url = `${getApiUrl()}${ApiRoutes.RECOMMENDED_CHARITIES}?limit=${limit}&randomness=${randomness}`;

      const requestOptions = constructGetRequestOptions();

      const handleSuccess = (data: { results: Charity[] }) => {
        set((state) => ({
          ...state,
          recommendedCharities: data.results,
          recommendedCharitiesLoading: false,
        }));
      };

      const handleError = () => {
        displayError(
          'There was an issue fetching recommended charities. Please try again.',
        );
        set((state) => ({ ...state, recommendedCharitiesLoading: false }));
      };

      addToFetchQueue(url, requestOptions, handleSuccess, handleError);
    },
  }),
);
