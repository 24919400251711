import { create } from 'zustand';
import { Charity } from './charityStore';
import { useNetworkStore } from './networkStore';
import { useUserStore } from './userStore';
import { useUIStore } from './uiStore';
import { ApiRoutes, getApiUrl } from '../utils/get-api-url';
import { constructPostRequestOptions } from '../utils/construct-post-request-options';
import { constructGetRequestOptions } from '../utils/construct-get-request-options';

export interface Donation {
  percentage: number;
  charity: Charity;
}

export interface ProcessedDonation extends Donation {
  amount: string;
}

export interface Payment {
  transaction_id: string;
  received_at: string;
  amount: string;
  state: 'IN PROGRESS' | 'COMPLETED' | 'HALTED' | 'REFUNDED';
  donation_config: ProcessedDonation[];
}

interface DonationsState {
  currentDonationConfig: Donation[] | [];
  donationsLoading: boolean;
  userHasClearedDonationConfig: boolean;
  donationsList: Payment[] | [];
}

interface DonationsActions {
  setDonationsLoading: (isLoading: boolean) => void;
  adjustDonationConfig: (donations: Donation[], sendToServer: boolean) => void;
  sendDonations: () => void;
  fetchDonationsList: () => void;
  subscribeToDonationsList: () => void;
}

export const useDonationsStore = create<DonationsState & DonationsActions>(
  (set, get) => ({
    currentDonationConfig: [],
    donationsLoading: true,
    userHasClearedDonationConfig: false,
    donationsList: [],

    setDonationsLoading: (isLoading) => {
      set((state) => ({
        ...state,
        donationsLoading: isLoading,
      }));
    },

    adjustDonationConfig: (donations, sendToServer = false) => {
      set((state) => ({
        ...state,
        currentDonationConfig: donations,
        // This lets us differentiate between a user who has no
        // donations and a user who has cleared their donations
        userHasClearedDonationConfig: donations.length === 0,
      }));

      if (!sendToServer) return;

      get().sendDonations();
    },
    sendDonations: () => {
      const { addToFetchQueue } = useNetworkStore.getState();
      const { csrfToken } = useUserStore.getState();
      const { displayError } = useUIStore.getState();
      const { currentDonationConfig } = get();

      const url = `${getApiUrl()}${ApiRoutes.USER_DONATIONS}`;

      const requestBody = {
        charities: currentDonationConfig,
      };

      const requestOptions = constructPostRequestOptions(
        csrfToken,
        requestBody,
      );

      const handleError = () => {
        displayError(
          'There was an issue saving your donations to the server. Please try again.',
        );
      };

      addToFetchQueue(url, requestOptions, () => null, handleError);
    },

    fetchDonationsList: async () => {
      const { addToFetchQueue } = useNetworkStore.getState();
      const { displayError } = useUIStore.getState();

      const url = `${getApiUrl()}${ApiRoutes.PAYMENTS_LIST}`;

      const requestOptions = constructGetRequestOptions();

      const handleError = () => {
        displayError(
          'There was an issue fetching your payments from the server. Please try again.',
        );
      };

      const handleSuccess = (data: any) => {
        set((state) => ({
          ...state,
          donationsList: data?.payments || [],
        }));
      };

      addToFetchQueue(url, requestOptions, handleSuccess, handleError);
    },

    subscribeToDonationsList: () => {
      const { isOnline } = useNetworkStore.getState();
      const { fetchDonationsList } = get();

      fetchDonationsList();

      setInterval(
        () => {
          if (!isOnline) return;
          fetchDonationsList();
        },
        1000 * 60 * 5,
      ); // every 5 minutes
    },
  }),
);
