import copy from 'copy-to-clipboard';
import { useUserStore } from '../../app-state/userStore';
import { useDonationsStore } from '../../app-state/donationsStore';
import { formatNumberString } from '../../utils/format-number-string';
import { Logout } from '../../components/logout/logout';
import { DocumentDuplicateIcon } from '@heroicons/react/20/solid';
import { formatDateString } from '../../utils/format-date-string';
import { DarkModeToggle } from '../../components/dark-mode-toggle/dark-mode-toggle';
import { Card } from '../../components/card/card';

export const Account = () => {
  const donationsList = useDonationsStore((state) => state.donationsList);
  const email = useUserStore((state) => state.email);
  const paymentReference = useUserStore((state) => state.paymentReference);

  return (
    <>
      {email && (
        <>
          <h1>Your account</h1>

          <div className="flex items-center gap-4">
            <span data-testid="user-data">{email}</span>

            <Logout />
          </div>

          <div className="flex items-center gap-4 my-4">
            <span>Toggle app theme:</span>

            <DarkModeToggle />
          </div>

          {paymentReference && (
            <div className="mt-12 max-w-3xl overflow-hidden">
              <Card defaultPadding={false}>
                <div className="w-full border-b border-gray-200 dark:border-gray-700 py-3 px-6 bg-white dark:bg-gray-800 font-semibold">
                  Your unique payment reference
                </div>
                <button
                  onClick={() => copy(paymentReference)}
                  title="copy to clipboard"
                >
                  <div className="p-6 flex items-center gap-3">
                    <div className="text-lg">{paymentReference}</div>
                    <DocumentDuplicateIcon className="w-5 h-5" />
                  </div>
                </button>
                <p className="px-6 pb-4 text-sm">
                  Include this reference when making a donation via Fruitcake,
                  this lets us match your donation to your account so we can
                  split the donation between your chosen charities.
                </p>
              </Card>
            </div>
          )}

          <div
            id="donation-history"
            className="mt-12 max-w-5xl overflow-hidden"
          >
            <Card defaultPadding={false}>
              <div className="w-full border-b border-gray-200 dark:border-gray-700 py-3 px-6 bg-white dark:bg-gray-800 font-semibold">
                Your donation history
              </div>

              <div className="text-sm px-6 py-3">
                (Donations can take up to an hour to appear here)
              </div>

              {donationsList.map((donation) => (
                <div
                  key={donation.transaction_id}
                  className="p-6 border-t border-gray-200 dark:border-gray-700"
                >
                  <div className="flex flex-col md:flex-row gap-3 justify-between">
                    <div className="flex flex-col md:flex-row items-start md:items-center gap-3">
                      <strong className="mr-1">
                        {formatDateString(donation.received_at)}
                      </strong>
                      <div>
                        <span className="text-sm mr-1">Transaction ID:</span>{' '}
                        <strong>{donation.transaction_id}</strong>
                      </div>
                      <div>
                        <span className="text-sm mr-1">amount:</span> £
                        <strong>{donation.amount}</strong>
                      </div>
                    </div>
                    <div>
                      <span className="text-sm">Status:</span>{' '}
                      <strong>{donation.state.toLowerCase()}</strong>
                    </div>
                  </div>
                  {donation.donation_config &&
                    donation.donation_config.length > 0 && (
                      <div className="mt-4">
                        {donation.donation_config.map((donationConfig) => (
                          <div
                            key={donationConfig.charity.id}
                            className="ml-6 mt-1 text-sm"
                          >
                            <strong>{donationConfig.charity.name}:</strong>{' '}
                            {formatNumberString(donationConfig.percentage)}%
                          </div>
                        ))}
                      </div>
                    )}
                </div>
              ))}
            </Card>
          </div>
        </>
      )}
    </>
  );
};
