export enum Urls {
  development = 'http://localhost:8000',
  production = 'https://api.fruitcakeapp.co.uk',
  test = 'https://test.api.fruitcakeapp.co.uk',
}

export enum ApiRoutes {
  AUTH_LOGIN_REGISTER = '/api/v1/auth/login-register/',
  AUTH_LOGOUT = '/api/v1/auth/logout/',
  USER_PROFILE = '/api/v1/user/profile/',
  USER_DONATIONS = '/api/v1/user/donation-config/',
  CHARITY_LIST = '/api/v1/charities/list/',
  CSRF_TOKEN = '/api/v1/auth/csrf-token/',
  PAYMENTS_LIST = '/api/v1/user/payments/list/',
  APP_INFO = '/api/v1/app/info/',
  NOTIFICATIONS = '/api/v1/user/notifications/list/',
  NOTIFICATIONS_MARK_SEEN = '/api/v1/user/notifications/mark-as-seen/',
  RECOMMENDED_CHARITIES = '/api/v1/charities/recommended/',
}

export const getApiUrl = () => {
  const environment = process.env.NODE_ENV;
  if (!environment) {
    throw new Error('The NODE_ENV environment variable is not set.');
  }
  if (!Urls[environment]) {
    throw new Error(`No API URL defined for the "${environment}" environment.`);
  }
  return Urls[environment];
};
