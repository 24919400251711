import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useUserStore } from './app-state/userStore';
import { ApiConnector } from './components/api-connector/api-connector';
import { Header } from './components/header/header';
import { Footer } from './components/footer/footer';
import { AppRouter } from './routes/app-router';
import { ErrorMessageBanner } from './components/error-message-banner/error-message-banner';
import { SetupSteps } from './components/intro-steps/setup-steps';
import { DonateDialog } from './components/donate-dialog/donate-dialog';
import { LoginDialog } from './components/login-dialog/login-dialog';
import { ExampleMonetaryDialog } from './components/example-monetary-dialog/example-monetary-dialog';
import { NotificationsManager } from './components/notifications-manager/notifications-manager';
import * as ToastPrimitive from '@radix-ui/react-toast';
import './styles.css';
import { useUIStore } from './app-state/uiStore';

const App = () => {
  const { setTheme } = useUIStore.getState();
  const theme = useUIStore((state) => state.theme);
  const email = useUserStore((state) => state.email);
  const isLoggedIn = !!email;

  useEffect(() => {
    // @ts-ignore - Preline styling library setup
    import('preline');
  }, []);

  useEffect(() => {
    const bodyClasses = 'blended-background text-gray-800 dark:text-gray-300';
    const classList = bodyClasses.split(' ');

    // Add classes to body when component mounts
    document.body.classList.add(...classList);

    return () => {
      // Remove classes from body when component unmounts
      document.body.classList.remove(...classList);
    };
  }, []);

  useEffect(() => {
    setTheme(theme);
  }, [setTheme, theme]);

  return (
    <BrowserRouter>
      <ToastPrimitive.Provider swipeDirection="right">
        <ApiConnector>
          <>
            <ErrorMessageBanner />
            <div className="h-screen grid grid-rows-[auto,1fr,auto]">
              <Header />
              <main className="w-full max-w-8xl mx-auto pt-10 px-4">
                {isLoggedIn && <SetupSteps />}
                <AppRouter />
              </main>
              <Footer />
            </div>
            <NotificationsManager />
            <DonateDialog />
            <LoginDialog />
            <ExampleMonetaryDialog />
          </>
        </ApiConnector>
      </ToastPrimitive.Provider>
    </BrowserRouter>
  );
};

export default App;
