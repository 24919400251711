import { formatDateString } from '../../utils/format-date-string';

interface ImpactSummaryProps {
  startDate: Date | null;
  endDate: Date | null;
  numberOfUniqueCharities: number;
  numberOfDonations: number;
  totalDonated: string;
}

export const ImpactSummarySentence = ({
  startDate,
  endDate,
  numberOfUniqueCharities,
  numberOfDonations,
  totalDonated,
}: ImpactSummaryProps) => {
  const hasStartDate = startDate !== null;
  const hasEndDate = endDate !== null;

  return (
    <div className="mt-4">
      <h2 className="m-0 text-lg font-semibold">
        {hasStartDate || hasEndDate
          ? `Between ${
              hasStartDate
                ? formatDateString(startDate!.toString(), false)
                : 'Starting with Fruitcake'
            } and ${
              hasEndDate
                ? formatDateString(endDate!.toString(), false)
                : 'today'
            }, you `
          : "Since starting with Fruitcake, you've "}
        donated to{' '}
        <span className="highlighted glow-flash p-1 dark:text-black">
          {numberOfUniqueCharities}
        </span>{' '}
        {numberOfUniqueCharities === 1 ? 'charity' : 'different charities'} over{' '}
        <span className="highlighted glow-flash p-1 dark:text-black">
          {numberOfDonations}
        </span>{' '}
        {numberOfDonations === 1 ? 'donation' : 'donations'}, totaling{' '}
        <span className="highlighted glow-flash p-1 dark:text-black">
          {totalDonated}
        </span>
      </h2>
    </div>
  );
};
