import { useCsrfToken } from './use-csrf-token';
import { constructPostRequestOptions } from './construct-post-request-options';
import { useNetworkStore } from '../app-state/networkStore';
import { useUIStore } from '../app-state/uiStore';

export const usePostRequest = () => {
  const { addToFetchQueue } = useNetworkStore.getState();
  const { displayError } = useUIStore.getState();
  const { csrfToken, csrfTokenLoading } = useCsrfToken();

  const post = async <T>(
    url: string,
    body?: T,
    onSuccess?: (data: any) => void,
    onError?: (error: Error) => void,
  ) => {
    if (!csrfToken && !csrfTokenLoading) {
      displayError(
        'We were unable to process your request, please reload and try again.',
      );
    }

    if (csrfToken) {
      try {
        const requestOptions = constructPostRequestOptions(csrfToken, body);
        addToFetchQueue(url, requestOptions, onSuccess, onError);
      } catch (error) {
        // Request-specific error handling will be within any 'onError' callback, so here we'll just provide
        // a generic error message.
        displayError(
          'An error occurred while processing your request, please reload and try again.',
        );
      }
    }
  };

  return { post, csrfTokenLoading };
};
