import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from '../button/button';

interface ImpactDateRangePickerProps {
  startDate: Date | null;
  endDate: Date | null;
  onStartDateChange: (date: Date | null) => void;
  onEndDateChange: (date: Date | null) => void;
  onClearDates: () => void;
  maxDate?: Date;
}

export const ImpactDateRangePicker = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  onClearDates,
  maxDate = new Date(),
}: ImpactDateRangePickerProps) => {
  return (
    <div className="mb-8 py-4">
      <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
        Select your donation date range (all donations are shown if no range is
        selected):
      </label>
      <div className="flex items-center space-x-4">
        <DatePicker
          selected={startDate}
          onChange={onStartDateChange}
          selectsStart
          startDate={startDate || undefined}
          endDate={endDate || undefined}
          maxDate={endDate || maxDate}
          dateFormat="dd/MM/yyyy"
          placeholderText="Start date"
          className="form-input mt-1 block w-full text-black dark:text-black"
        />
        <DatePicker
          selected={endDate}
          onChange={onEndDateChange}
          selectsEnd
          startDate={startDate || undefined}
          endDate={endDate || undefined}
          minDate={startDate || undefined}
          maxDate={maxDate}
          dateFormat="dd/MM/yyyy"
          placeholderText="End date"
          className="form-input mt-1 block w-full text-black dark:text-black"
        />

        <div className="mt-1">
          <Button onClick={onClearDates} disabled={!startDate && !endDate}>
            Clear dates
          </Button>
        </div>
      </div>
    </div>
  );
};
