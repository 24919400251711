export const routes = {
  HOME: '/',
  ACCOUNT: '/account',
  DISCOVER: '/discover',
  CHARITY_DETAILS: '/discover/:charityId',
  DONATION_CONFIG: '/donation-config',
  IMPACT: '/impact',
  FAQS: '/faqs',
  ABOUT_US: '/about-us',
  FAILED_LOGIN_LINK: '/failed-login-link',
  CHECK_EMAIL: '/check-your-email',
};
