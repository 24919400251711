import { create } from 'zustand';
import { useNetworkStore } from './networkStore';
import { useUIStore } from './uiStore';
import { ApiRoutes, getApiUrl } from '../utils/get-api-url';
import { constructGetRequestOptions } from '../utils/construct-get-request-options';

export interface AppInfo {
  fruitcake_percentage: number;
}

interface AppInfoState {
  appInfo?: AppInfo;
}

interface AppInfoActions {
  fetchAppInfo: () => void;
}

export const useAppInfoStore = create<AppInfoState & AppInfoActions>((set) => ({
  appInfo: undefined,

  fetchAppInfo: async () => {
    const { addToFetchQueue } = useNetworkStore.getState();
    const { displayError } = useUIStore.getState();

    const url = `${getApiUrl()}${ApiRoutes.APP_INFO}`;

    const requestOptions = constructGetRequestOptions();

    const handleError = () => {
      displayError(
        'There was an issue fetching some details from the server. Please reload the page and try again.',
      );
    };

    const handleSuccess = (data: any) => {
      set((state) => ({
        ...state,
        appInfo: data,
      }));
    };

    addToFetchQueue(url, requestOptions, handleSuccess, handleError);
  },
}));
