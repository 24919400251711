import { useEffect } from 'react';
import { CharityList } from '../charity-list/charity-list';
import { useCharityStore } from '../../app-state/charityStore';

interface RecommendedCharitiesProps {
  recommendationCount?: number;
}

export const RecommendedCharities = ({
  recommendationCount = 4,
}: RecommendedCharitiesProps) => {
  const recommendedCharities = useCharityStore(
    (state) => state.recommendedCharities,
  );
  const recommendedCharitiesLoading = useCharityStore(
    (state) => state.recommendedCharitiesLoading,
  );
  const { fetchRecommendedCharities } = useCharityStore.getState();

  useEffect(() => {
    if (recommendedCharities.length === 0 && !recommendedCharitiesLoading) {
      fetchRecommendedCharities(recommendationCount);
    }
  }, [
    fetchRecommendedCharities,
    recommendedCharities.length,
    recommendedCharitiesLoading,
    recommendationCount,
  ]);

  // Don't show the block if the fetch is in progress or no recommendations were found
  if (recommendedCharitiesLoading || recommendedCharities.length === 0) {
    return null;
  }

  return (
    <div className="highlighted -mx-6 my-8 px-6 pt-4 pb-6">
      <h2 className="text-black dark:text-black">
        Recommended charities for you
      </h2>
      <p className="-mt-3 mb-6 text-sm text-black dark:text-black">
        Based on your past donations, you might be interested in these charities
      </p>
      <CharityList
        charities={recommendedCharities}
        showAddCharity={false}
        smallLogos
      />
    </div>
  );
};
