import { Link } from 'react-router-dom';
import { routes } from '../../routes/routes';
import flower from './logo192.png';

export const Logo = () => (
  <Link to={routes.HOME}>
    <div
      data-testid="site-logo"
      className="grid grid-cols-[auto,1fr] items-center gap-2 text-black dark:text-white"
    >
      <img src={flower} alt="Fruitcake" className="h-8 w-8" />
      <span className="uppercase text-xl">Fruitcake</span>
    </div>
  </Link>
);
