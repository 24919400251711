import { Link } from 'react-router-dom';

interface ImpactQuickCharityDataCardProps {
  name: string;
  url: string;
  link: string;
  amountDonated: string;
  donationCount: number;
}

export const ImpactQuickCharityDataCard = ({
  name,
  url,
  link,
  amountDonated,
  donationCount,
}: ImpactQuickCharityDataCardProps) => {
  return (
    <div
      className="bg-white dark:bg-gray-700 rounded-lg shadow-md border border-gray-200 dark:border-gray-600 p-4 bounce-in-left"
      style={{ animationDelay: `${Math.random() * 1.5}s` }}
    >
      <Link to={link}>
        <div className="grid grid-cols-[auto_1fr] gap-4">
          <img
            src={url}
            alt={`${name} logo`}
            className="h-20 w-20 rounded-sm bg-white"
          />
          <div className="flex flex-col justify-around">
            <div className="font-semibold">{name}</div>
            <div className="text-sm">{amountDonated} donated</div>
            <div className="text-sm">
              {donationCount} donation{donationCount > 1 ? 's' : ''}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
