import { routes } from './routes';
import { Home } from './home/home';
import { Discover } from './discover/discover';
import { DonationConfig } from './donation-config/donation-config';
import { Impact } from './impact/impact';
import { Account } from './account/account';
import { Landing } from './landing/landing';
import { FAQs } from './faqs/faqs';
import { AboutUs } from './about-us/about-us';
import { CharityDetails } from './charity-details/charity-details';
import { FailedLoginLink } from './failed-login-link/failed-login-link';
import { CheckEmail } from './check-email/check-email';
import { NotFound } from './not-found/not-found';

const routesConfig = {
  'logged-out-home': {
    path: routes.HOME,
    element: <Landing />,
    name: 'Home',
    includeInHeader: false,
    includeInFooter: false,
  },
  'logged-in-home': {
    path: routes.HOME,
    element: <Home />,
    name: 'Home',
    includeInHeader: false,
    includeInFooter: false,
  },
  [routes.DISCOVER]: {
    path: routes.DISCOVER,
    element: <Discover />,
    name: 'Discover',
    includeInHeader: true,
    includeInFooter: false,
  },
  [routes.DONATION_CONFIG]: {
    path: routes.DONATION_CONFIG,
    element: <DonationConfig />,
    name: 'Your charities',
    includeInHeader: true,
    includeInFooter: false,
  },
  [routes.IMPACT]: {
    path: routes.IMPACT,
    element: <Impact />,
    name: 'Your impact',
    includeInHeader: true,
    includeInFooter: false,
  },
  [routes.CHARITY_DETAILS]: {
    path: routes.CHARITY_DETAILS,
    element: <CharityDetails />,
    includeInHeader: false,
    includeInFooter: false,
  },
  [routes.ACCOUNT]: {
    path: routes.ACCOUNT,
    element: <Account />,
    name: 'Account',
    includeInHeader: true,
    includeInFooter: false,
  },
  [routes.ABOUT_US]: {
    path: routes.ABOUT_US,
    element: <AboutUs />,
    name: 'About us',
    includeInHeader: false,
    includeInFooter: true,
  },
  [routes.CHECK_EMAIL]: {
    path: routes.CHECK_EMAIL,
    element: <CheckEmail />,
    name: 'Check your email',
    includeInHeader: false,
    includeInFooter: false,
  },
  [routes.FAILED_LOGIN_LINK]: {
    path: routes.FAILED_LOGIN_LINK,
    element: <FailedLoginLink />,
    name: 'Failed login',
    includeInHeader: false,
    includeInFooter: false,
  },
  'logged-out-faqs': {
    path: routes.FAQS,
    element: <FAQs />,
    name: 'FAQs',
    includeInHeader: true,
    includeInFooter: false,
  },
  'logged-in-faqs': {
    path: routes.FAQS,
    element: <FAQs />,
    name: 'FAQs',
    includeInHeader: false,
    includeInFooter: true,
  },
  '*': {
    path: '*',
    element: <NotFound />,
    name: 'Page not found',
    includeInHeader: false,
    includeInFooter: false,
  },
};

export const LoggedOutRoutesConfig = [
  routesConfig['logged-out-home'],
  routesConfig[routes.ABOUT_US],
  routesConfig[routes.CHECK_EMAIL],
  routesConfig[routes.FAILED_LOGIN_LINK],
  routesConfig['logged-out-faqs'],
  routesConfig['*'],
];

export const LoggedInRoutesConfig = [
  routesConfig['logged-in-home'],
  routesConfig[routes.DISCOVER],
  routesConfig[routes.DONATION_CONFIG],
  routesConfig[routes.IMPACT],
  routesConfig[routes.CHARITY_DETAILS],
  routesConfig[routes.ACCOUNT],
  routesConfig['logged-in-faqs'],
  routesConfig[routes.ABOUT_US],
  routesConfig[routes.FAILED_LOGIN_LINK],
  routesConfig['*'],
];
