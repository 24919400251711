import { XMarkIcon } from '@heroicons/react/20/solid';
import { useUIStore } from '../../app-state/uiStore';

export const ErrorMessageBanner = () => {
  const { clearError } = useUIStore.getState();
  const errorToDisplay = useUIStore((state) => state.errorToDisplay);

  return (
    <div className="relative z-50">
      <div className="w-full">
        {errorToDisplay && (
          <>
            <div className="flex items-center gap-x-6 bg-red-400 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
              <p className="m-1 text-md leading-6 text-white">
                {errorToDisplay}
              </p>
              <div className="flex flex-1 justify-end">
                <button
                  type="button"
                  className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
                  onClick={() => clearError()}
                >
                  <span className="sr-only">Dismiss</span>
                  <XMarkIcon
                    className="h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
