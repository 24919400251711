import { Fragment, ReactNode } from 'react';
import { Dialog as HeadlessUiDialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';

interface DialogProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children: ReactNode;
}

export const Dialog = ({ isOpen, onClose, title, children }: DialogProps) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <HeadlessUiDialog as="div" className="relative z-10" onClose={onClose}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <HeadlessUiDialog.Panel className="relative m-4 transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 shadow-xl">
              <button
                onClick={onClose}
                className="absolute top-3 right-3 rounded-full bg-gray-100 p-2 text-sm font-medium text-gray-700 hover:bg-gray-200"
              >
                <XMarkIcon className="h-5 w-5" />
              </button>

              {title && (
                <HeadlessUiDialog.Title
                  as="h2"
                  className="text-lg font-semibold text-gray-900 mb-0 py-6 px-12 text-center"
                >
                  {title}
                </HeadlessUiDialog.Title>
              )}

              <div className="p-6">{children}</div>
            </HeadlessUiDialog.Panel>
          </div>
        </div>
      </HeadlessUiDialog>
    </Transition.Root>
  );
};
