import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useUIStore } from '../../app-state/uiStore';
import { useDonationsStore, Donation } from '../../app-state/donationsStore';
import { Charity } from '../../app-state/charityStore';
import { useCharityAdd } from '../../utils/use-charity-add';
import {
  checkHasOtherDonations,
  checkIsDonatingToCharity,
} from '../charity-list-item/charity-list-item';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../routes/routes';
import { dp } from '../../utils/dp';

interface CharityAddProps {
  charity: Charity;
  textFlexAlign?: 'col' | 'row';
}

export const CharityAdd = ({ charity, textFlexAlign }: CharityAddProps) => {
  const donationsLoading = useDonationsStore((state) => state.donationsLoading);
  const currentDonationConfig = useDonationsStore(
    (state) => state.currentDonationConfig,
  );
  const { setCurrentDonationToHighlight } = useUIStore.getState();

  const [charityDonation, setCharityDonation] = useState<Donation | null>(null);
  const [hasOtherDonations, setHasOtherDonations] = useState(false);
  const { handleCharityAdd, charitiesUpdateState } = useCharityAdd();
  const navigate = useNavigate();

  useEffect(() => {
    if (!donationsLoading && currentDonationConfig.length > 0) {
      setHasOtherDonations(
        checkHasOtherDonations(currentDonationConfig, charity.id),
      );
      setCharityDonation(
        checkIsDonatingToCharity(currentDonationConfig, charity.id),
      );
    }
  }, [charity.id, donationsLoading, currentDonationConfig]);

  const ctaStyles = classNames(
    'w-full py-3 px-4 inline-flex justify-center items-center font-medium shadow-sm align-middle transition-all text-sm sm:p-4 bg-green-300 hover:bg-green-400 text-gray-800',
    {
      'flex-row gap-1': textFlexAlign === 'row',
      'flex-col gap-0': !textFlexAlign || textFlexAlign === 'col',
    },
  );

  const handleAdjustClick = () => {
    console.log('adding donation to highlight', charity.id);
    setCurrentDonationToHighlight(charity.id);
    navigate(routes.DONATION_CONFIG);
  };

  return charityDonation ? (
    <button
      onClick={handleAdjustClick}
      className={ctaStyles}
      data-testid="adjust-donation-btn"
    >
      <span>Adjust donation</span>
      <span>(currently {dp(charityDonation.percentage, 1)}%)</span>
    </button>
  ) : (
    <button
      className={ctaStyles}
      data-testid="add-donation-btn"
      onClick={async () => {
        await handleCharityAdd(charity.id, hasOtherDonations ? 0 : 100);
      }}
      disabled={charitiesUpdateState.charityAddInProgress || donationsLoading}
    >
      Add to my charities
    </button>
  );
};
