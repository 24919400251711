import { FooterNavItems } from '../footer-nav-items/footer-nav-items';

export const Footer = () => (
  <footer className="w-full border-t border-gray-200 text-sm mt-6 py-6 bg-white dark:bg-gray-800 dark:border-gray-700">
    <div className="w-full flex justify-between max-w-8xl mx-auto px-4">
      <span>&copy; {new Date().getFullYear()} Fruitcake</span>

      <FooterNavItems />
    </div>
  </footer>
);
