import { Component, ReactElement } from 'react';

// Note: only class components can be error boundaries. Bleurgh.
interface RouteErrorBoundaryProps {
  children: ReactElement;
}
export class RouteErrorBoundary extends Component<
  RouteErrorBoundaryProps,
  { hasError: boolean }
> {
  constructor(props: RouteErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  override componentDidCatch(error: Error) {
    // We'll likely want to implement Sentry or similar here.
    console.log(error);
  }

  override render() {
    if (this.state.hasError) {
      return <h1>Something went wrong</h1>;
    }

    return this.props.children;
  }
}
