import aboutUsImage from '../../assets/sam-and-adam.jpg';

export const AboutUs = () => (
  <div>
    <h1>About us</h1>
    <div className="flex flex-col md:flex-row-reverse gap-6">
      <div>
        <p>
          Fruitcake's co-founders are Adam Alton and Sam Keen. We've been
          friends since childhood, playing in bands and writing music together.
          We both ended up having careers in web development, and one day we
          started discussing using our skills in ways that help others.
          Fruitcake is the result of those discussions.
        </p>
        <p>
          Many people find the process of donating to charity inflexible and
          unappealing. Our goal is to create a platform that allows people to be
          generous in a way that suits them. By doing so, we hope to increase
          the amount of money donated to charity and make the world a better
          place.
        </p>
      </div>

      <img
        src={aboutUsImage}
        alt="Sam and Adam"
        className="rounded-sm w-64 md:w-80"
      />
    </div>
  </div>
);
