import { create } from 'zustand';

interface UIState {
  errorToDisplay: string | null;
  isDonateDialogOpen: boolean;
  isLoginDialogOpen: boolean;
  exampleTotal?: string;
  showExampleTotal: boolean;
  isExampleMonetaryDialogOpen: boolean;
  theme: 'light' | 'dark';
  currentDonationToHighlight?: string;
}

interface UIActions {
  displayError: (error: string) => void;
  clearError: () => void;
  openDonateDialog: () => void;
  closeDonateDialog: () => void;
  openLoginDialog: () => void;
  closeLoginDialog: () => void;
  setExampleTotal: (total: string) => void;
  setShowExampleTotal: (show: boolean) => void;
  openExampleTotalDialog: () => void;
  closeExampleTotalDialog: () => void;
  setTheme: (theme?: 'light' | 'dark') => void;
  toggleTheme: () => void;
  setCurrentDonationToHighlight: (charityId: string) => void;
  clearCurrentDonationToHighlight: () => void;
}

const isBrowser = typeof window !== 'undefined';

const getInitialTheme = (): 'light' | 'dark' => {
  if (!isBrowser) return 'light';

  const storedTheme = localStorage.getItem('theme');
  if (storedTheme === 'light' || storedTheme === 'dark') {
    return storedTheme;
  }

  return window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light';
};

export const useUIStore = create<UIState & UIActions>((set, get) => ({
  errorToDisplay: null,
  isDonateDialogOpen: false,
  isLoginDialogOpen: false,
  exampleTotal: undefined,
  showExampleTotal: false,
  isExampleMonetaryDialogOpen: false,
  theme: getInitialTheme(),
  currentDonationToHighlight: undefined,

  displayError: (error) => {
    set((state) => ({
      ...state,
      errorToDisplay: error,
    }));
  },
  clearError: () => {
    set((state) => ({
      ...state,
      errorToDisplay: null,
    }));
  },
  setExampleTotal: (total) => {
    set((state) => ({
      ...state,
      exampleTotal: total,
    }));
  },
  setShowExampleTotal: (show) => {
    set((state) => ({
      ...state,
      showExampleTotal: show,
    }));
  },
  openDonateDialog: () => {
    set((state) => ({
      ...state,
      isDonateDialogOpen: true,
    }));
  },
  closeDonateDialog: () => {
    set((state) => ({
      ...state,
      isDonateDialogOpen: false,
    }));
  },
  openLoginDialog: () => {
    set((state) => ({
      ...state,
      isLoginDialogOpen: true,
    }));
  },
  closeLoginDialog: () => {
    set((state) => ({
      ...state,
      isLoginDialogOpen: false,
    }));
  },
  openExampleTotalDialog: () => {
    set((state) => ({
      ...state,
      isExampleMonetaryDialogOpen: true,
    }));
  },
  closeExampleTotalDialog: () => {
    set((state) => ({
      ...state,
      isExampleMonetaryDialogOpen: false,
    }));
  },
  setTheme: (theme) => {
    if (theme) {
      localStorage.setItem('theme', theme);
      if (theme === 'dark') {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
      set((state) => ({
        ...state,
        theme,
      }));
    }
  },
  toggleTheme: () => {
    const currentTheme = get().theme;
    const newTheme = currentTheme === 'light' ? 'dark' : 'light';
    get().setTheme(newTheme);
  },
  setCurrentDonationToHighlight: (charityId) => {
    set((state) => ({
      ...state,
      currentDonationToHighlight: charityId,
    }));
  },
  clearCurrentDonationToHighlight: () => {
    set((state) => ({
      ...state,
      currentDonationToHighlight: undefined,
    }));
  },
}));
