import { create } from 'zustand';
import { useNetworkStore } from './networkStore';
import { useUIStore } from './uiStore';
import { ApiRoutes, getApiUrl } from '../utils/get-api-url';
import { constructGetRequestOptions } from '../utils/construct-get-request-options';
import { useUserStore } from './userStore';
import { constructPostRequestOptions } from '../utils/construct-post-request-options';

interface NotificationPaymentReceivedData {
  transaction_id: string;
  amount_display: string;
}

interface NotificationNoCharitiesConfiguredForPaymentData {
  transaction_id: string;
  amount_display: string;
}

interface CharityNamePercentagePair {
  charity_name: string;
  percentage: number;
}

interface NotificationPaymentAllocatedData {
  transaction_id: string;
  amount_display: string;
  charity_name_percentage_pairs: CharityNamePercentagePair[];
}

export interface NotificationBase {
  id: string;
  is_seen: boolean;
  seen_at: string | null;
  email_sent: boolean;
  created_at: string;
}

export interface PaymentReceivedNotification extends NotificationBase {
  category: 'PAYMENT_RECEIVED';
  data: NotificationPaymentReceivedData;
}

export interface NoCharitiesConfiguredForPaymentNotification
  extends NotificationBase {
  category: 'NO_CHARITIES_CONFIGURED_FOR_PAYMENT';
  data: NotificationNoCharitiesConfiguredForPaymentData;
}

export interface PaymentAllocatedNotification extends NotificationBase {
  category: 'PAYMENT_ALLOCATED';
  data: NotificationPaymentAllocatedData;
}

// Union of all notification types
export type Notification =
  | PaymentReceivedNotification
  | NoCharitiesConfiguredForPaymentNotification
  | PaymentAllocatedNotification;

interface NotificationsState {
  notifications: Notification[];
}

interface NotificationsActions {
  fetchNotifications: () => void;
  subscribeToNotifications: () => void;
  markNotificationAsSeen: (
    notificationId: string,
    onSuccess?: () => void,
  ) => void;
}

export const useNotificationsStore = create<
  NotificationsState & NotificationsActions
>((set, get) => ({
  notifications: [],

  fetchNotifications: async () => {
    const { addToFetchQueue } = useNetworkStore.getState();
    const { displayError } = useUIStore.getState();

    const url = `${getApiUrl()}${ApiRoutes.NOTIFICATIONS}`;

    const requestOptions = constructGetRequestOptions();

    const handleError = () => {
      displayError(
        'There was an issue fetching notifications from the server. Please try again.',
      );
    };

    const handleSuccess = (data: any) => {
      set((state) => ({
        ...state,
        notifications: data?.results || [],
      }));
    };

    addToFetchQueue(url, requestOptions, handleSuccess, handleError);
  },
  subscribeToNotifications: () => {
    const { isOnline } = useNetworkStore.getState();
    const { fetchNotifications } = get();

    fetchNotifications();

    setInterval(
      () => {
        if (!isOnline) return;
        fetchNotifications();
      },
      1000 * 60 * 5, // Poll every 5 minutes
    );
  },
  markNotificationAsSeen: (id: string, handleSuccess?: () => void) => {
    const { addToFetchQueue } = useNetworkStore.getState();
    const { csrfToken } = useUserStore.getState();
    const { displayError } = useUIStore.getState();

    const url = `${getApiUrl()}${ApiRoutes.NOTIFICATIONS_MARK_SEEN}`;

    const requestBody = { id };

    const requestOptions = constructPostRequestOptions(csrfToken, requestBody);

    const handleError = () => {
      displayError(
        'There was an issue marking the notification as seen. Please try again.',
      );
    };

    addToFetchQueue(url, requestOptions, handleSuccess, handleError);
  },
}));
