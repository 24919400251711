import { useNavigate } from 'react-router-dom';
import { routes } from '../routes';
import { Button } from '../../components/button/button';

export const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div>
      <h1>Page not found</h1>
      <p>We couldn't find the page you were looking for</p>
      <div>
        <Button variant="default" onClick={() => navigate(routes.HOME)}>
          Head home
        </Button>
      </div>
    </div>
  );
};
