import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { LoggedInRoutesConfig, LoggedOutRoutesConfig } from './routes-config';
import { useUserStore } from '../app-state/userStore';
import { RouteErrorBoundary } from './route-error-boundary';
import { LoadingSpinner } from '../components/loading-spinner/loading-spinner';

export const AppRouter = () => {
  const email = useUserStore((state) => state.email);
  const emailLoading = useUserStore((state) => state.emailLoading);

  const isLoggedIn = !!email;
  const routeConfig = isLoggedIn ? LoggedInRoutesConfig : LoggedOutRoutesConfig;
  const routes = useRoutes(routeConfig);

  if (emailLoading) {
    return <LoadingSpinner />;
  }

  return (
    <RouteErrorBoundary>
      <Suspense fallback={<LoadingSpinner />}>{routes}</Suspense>
    </RouteErrorBoundary>
  );
};
