export const formatNumberString = (value: string | number) => {
  const num = Number(value);
  const formattedValue = num.toFixed(2);

  // Remove the decimal part if it's '.00'
  if (formattedValue.endsWith('.00')) {
    return formattedValue.slice(0, -3);
  }

  return formattedValue;
};
