import { create } from 'zustand';

export interface FetchRequestOptions {
  method: string;
  credentials: RequestCredentials;
  headers?: {
    'Content-Type': string;
    'X-CsrfToken': string;
  };
  body?: string | null;
}

export interface FetchRequest {
  id: string;
  url: string;
  options: FetchRequestOptions;
  attempts: number;
  onSuccess?: (data: any) => void;
  onError?: (error: Error) => void;
}

interface NetworkState {
  isOnline: boolean;
  fetchQueue: FetchRequest[];
  fetchQueueProcessing: boolean;
}

interface NetworkActions {
  setOnlineStatus: (isOnline: boolean) => void;
  addToFetchQueue: (
    url: string,
    options: FetchRequestOptions,
    onSuccess?: (data: any) => void,
    onError?: (error: Error) => void,
  ) => void;
  processFetchQueue: () => Promise<void>;
}

const MAX_RETRIES = 3;

export const useNetworkStore = create<NetworkState & NetworkActions>(
  (set, get) => ({
    isOnline: window.navigator.onLine,
    fetchQueue: [],
    fetchQueueProcessing: false,

    setOnlineStatus: (isOnline) => {
      set({ isOnline });

      if (isOnline) {
        get().processFetchQueue();
      }
    },

    addToFetchQueue: (url, options, onSuccess, onError) => {
      const id = `${url}`;

      set((state) => ({
        fetchQueue: [
          ...state.fetchQueue,
          { id, url, options, attempts: 0, onSuccess, onError },
        ],
      }));

      get().processFetchQueue();
    },

    processFetchQueue: async () => {
      const { isOnline, fetchQueue, fetchQueueProcessing } = get();

      if (!isOnline) {
        console.log('App is offline, pausing fetch queue');
        return;
      }

      if (fetchQueue.length === 0) return;

      // Ensure no concurrent processing
      if (fetchQueueProcessing) {
        setTimeout(() => get().processFetchQueue(), 100);
        return;
      }

      set({ fetchQueueProcessing: true });

      // Make a copy of the queue to process
      const inProgressQueue = [...fetchQueue];

      // Clear the fetch queue for new incoming requests
      set({ fetchQueue: [] });

      const uniqueRequests = Array.from(
        new Set(inProgressQueue.map((request) => request.id)),
      ).map((id) => inProgressQueue.find((request) => request?.id === id));

      for (const request of uniqueRequests) {
        if (!request) continue;

        try {
          const response = await Promise.race([
            fetch(request.url, request.options),
            new Promise((_, reject) =>
              setTimeout(
                () =>
                  reject(new Error('Timeout: Could not connect to the server')),
                2000,
              ),
            ),
          ]);

          if (response instanceof Response) {
            if (!response.ok) {
              if (response.status === 401) {
                console.log(`Unauthorized request to ${request.url}`);
              } else {
                throw new Error(`Fetch failed with status: ${response.status}`);
              }
            }

            const data = await response.json();
            if (request.onSuccess) request.onSuccess(data);
          } else {
            throw response;
          }
        } catch (error) {
          if (request.onError) request.onError(error as Error);

          if (request.attempts < MAX_RETRIES) {
            // Requeue the request with an increased attempts count
            set((state) => ({
              fetchQueue: [
                ...state.fetchQueue,
                { ...request, attempts: request.attempts + 1 },
              ],
            }));
            get().processFetchQueue();
          } else {
            if (request.onError) {
              request.onError(error as Error);
            } else {
              console.error('There are issues connecting to the server.');
            }
          }
        }
      }

      set({ fetchQueueProcessing: false });
    },
  }),
);
